<template>
  <div>
    <table class="table_form" style="width: 100%; border-top: 0px">
      <tbody>
        <tr>
          <esp-dx-data-grid
            :data-grid="dataGrid"
            ref="lineAddGrid"
            :auto-width="true"
            @init-new-row="onInitNewRow"
          ></esp-dx-data-grid>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { isSuccess } from '@/plugins/common-lib';
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

  export default {
    components: {
      EspDxDataGrid,
    },
    props: {
      option: {
        default: () => {
          return {
            width: '80%',
            height: '85%',
            minWidth: null,
            minHeight: null,
            saveBtnVisible: false,
            cancelBtnVisible: true,
          };
        },
        type: Object,
      },

      deviceInfo: {
        type: Object,
      },
      serverInfo: {
        type: String,
      },
      isOpened: {
        type: Boolean,
      },
      authority: {
        type: String,
      },
      cmList: {
        type: Array,
      },
      partitionList: {
        type: Array,
      },
      cssList: {
        type: Array,
      },
      pickupGroupList: {
        type: Array,
      },
    },
    watch: {
      deviceInfo() {},
      async isOpened() {
        if (this.isOpened) {
          // this.mountedData();
        } else {
          this.refreshgGrid();
        }
      },
    },
    data() {
      return {
        rowCnt: 0,
        dataGrid: {
          refName: 'lineAddGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: false, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          disableTotalCount: true,
          dataSource: [],
          keyExpr: 'id',
          // callApi : '',
          // width:'200',     // 주석처리시 100%
          // height: '400', // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            initNewRow: true,
            saving: true,
          },
          showActionButtons: {
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: true,
            sorting: true,
            grouping: true,
            paging: true,
          },
          paging: {
            enabled: false,
            pageSize: 20,
            // pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '교환기',
              i18n: 'UC.WORD.EXCHANGER',
              dataField: 'cmNm',
              width: 100,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수값입니다.',
                i18n: 'COMMON.REQUIRED_VALUE',
              },
              cellTemplate: async (container, options) => {
                if (this.cmList.length === 1) {
                  options.value = this.cmList[0].cmNm;
                }
                const selectbox = new DxSelectBox({
                  propsData: {
                    placeholder: this.$_msgContents('COMPONENTS.SELECT'),
                    items: this.cmList,
                    displayExpr: 'description',
                    valueExpr: 'cmNm',
                    value: options.value,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      this.$refs.lineAddGrid.getGridInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                    },
                  },
                });
                selectbox.$mount();
                container.append(selectbox.$el);
              },
            },
            {
              caption: '내선번호',
              i18n: 'UC.WORD.NUMBER',
              dataField: 'pattern',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              validationRules: [
                {
                  type: 'custom',
                  validationCallback: function (e) {
                    // 숫자인 경우에만 true를 반환합니다.
                    return !isNaN(e.value);
                  },
                  message: this.$_msgContents('UC.MESSAGE.NUMBER_ENTER'),
                },
              ],
            },
            {
              caption: 'LABEL',
              i18n: 'UC.WORD.LABEL',
              dataField: 'label',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수값입니다.',
                i18n: 'COMMON.REQUIRED_VALUE',
              },
            },
            {
              caption: '파티션',
              i18n: 'UC.WORD.PARTITION',
              dataField: 'routepartitionname',
              width: 200,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              requiredRule: {
                message: '필수값입니다.',
                i18n: 'COMMON.REQUIRED_VALUE',
              },
              cellTemplate: async (container, options) => {
                const selectbox = new DxSelectBox({
                  propsData: {
                    placeholder: this.$_msgContents('COMPONENTS.SELECT'),
                    items: this.partitionList,
                    displayExpr: 'name',
                    valueExpr: 'name',
                    value: options.value,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      this.$refs.lineAddGrid.getGridInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                    },
                  },
                });
                selectbox.$mount();
                container.append(selectbox.$el);
              },
            },
            {
              caption: '발신검색',
              i18n: 'UC.WORD.CSS',
              dataField: 'css',
              width: 200,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              requiredRule: {
                message: '필수값입니다.',
                i18n: 'COMMON.REQUIRED_VALUE',
              },
              cellTemplate: async (container, options) => {
                const selectbox = new DxSelectBox({
                  propsData: {
                    placeholder: this.$_msgContents('COMPONENTS.SELECT'),
                    items: this.cssList,
                    displayExpr: 'name',
                    valueExpr: 'name',
                    value: options.value,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      this.$refs.lineAddGrid.getGridInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                    },
                  },
                });
                selectbox.$mount();
                container.append(selectbox.$el);
              },
            },

            {
              caption: '당겨받기',
              i18n: 'UC.WORD.PICKUPGROUP',
              dataField: 'callpickupgroupname',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              cellTemplate: async (container, options) => {
                const selectbox = new DxSelectBox({
                  propsData: {
                    placeholder: '선택',
                    items: this.pickupGroupList,
                    displayExpr: 'name',
                    valueExpr: 'name',
                    value: options.value,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      this.$refs.lineAddGrid.getGridInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                    },
                  },
                });
                selectbox.$mount();
                container.append(selectbox.$el);
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /**@description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.dataGrid.showActionButtons.customButtons = this.getCustomButtons();
      },
      /**@description : 라이프사이클 mounted시 호출되는 메서드 */
      mountedData() {},
      getCustomButtons() {
        const buttons = [
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: '삭제',
              elementAttr: { class: 'btn_XS white light_filled trash' },
              width: 60,
              height: 30,
              onClick: () => {
                this.onDeleteLine();
              },
            },
            location: 'before',
          },
        ];

        buttons.push({
          widget: 'dxButton',
          options: {
            icon: '',
            text: '생성',
            elementAttr: { class: 'btn_XS default filled' },
            width: 60,
            height: 30,
            onClick: () => {
              this.onCreateLine();
            },
          },
          location: 'after',
        });
        return buttons;
      },
      /** @description 그리드 신규 row 추가 시 이벤트 */
      onInitNewRow(e) {
        //교환기 정보 하나만 있을 경우 값 픽스
        if (this.cmList.length === 1) {
          e.data.cmNm = this.cmList[0].cmNm;
        }
        e.data.id = this.rowCnt;
        this.rowCnt++;
        this.$refs.lineAddGrid.getGridInstance.refresh();
      },
      /** @description 그리드 데이터 초기화 */
      refreshgGrid() {
        this.dataGrid.dataSource = [];
        this.$refs.lineAddGrid.getGridInstance.cancelEditData();
        this.rowCnt = 0;
      },
      async onDeleteLine() {
        let data = this.$refs.lineAddGrid.getGridInstance.getSelectedRowsData();
        if (data.length == 0) {
          await this.$_Msg('삭제할 데이터가 없습니다.');
          return false;
        }
        let keys = data.map(item => {
          let rowIndex = this.$refs.lineAddGrid.getGridInstance.getRowIndexByKey(item.id);
          return rowIndex;
        });
        keys.forEach(item => {
          this.$refs.lineAddGrid.getGridInstance.deleteRow(item);
        });
      },
      /**@description 내선 등록 함수 */
      async onCreateLine() {
        let data = this.$refs.lineAddGrid.getGridInstance.getDataSource()._items;

        if (this.$refs.lineAddGrid.hasEditData()) {
          await this.$_Msg('수정중인 항목이있습니다. ');
          return;
        }
        if (data.length == 0) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NO_DATA'));
          return;
        } else if (data.length > 10) {
          this.$_Msg(this.$_msgContents('UC.MESSAGE.CHECK_ADD_COUNT'));
        }
        let flag = true;
        let prevCm = '';
        for (let i = 0; i < data.length; i++) {
          if (i == 0) {
            prevCm = data[i].cmNm;
            continue;
          }
          if (prevCm !== data[i].cmNm) {
            flag = false;
          }
          prevCm = data[i].cmNm;
        }
        if (!flag) {
          this.$_Msg(this.$_msgContents('UC.MESSAGE.CHECK_EXCHANGE_TYPE'));
          return;
        }
        let params = { linelist: data, loginId: this.$store.getters.getLoginId };
        const payload = {
          actionname: 'EUC_LINE_INSERT',
          data: params,
          loading: true,
        };

        let confirm = this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' });

        if (!(await this.$_Confirm(confirm))) {
          return;
        }
        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
          this.$emit('closeModal', true);
        } else {
          await this.$_Msg(res.data.header.resMsg);
        }
      },
    },
    async created() {
      this.createdData();
    },
    async mounted() {
      // await this.mountedData();
    },
  };
</script>
